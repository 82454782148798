<template>
  <div id="nav">
    <Loader />
    <component :is="currentLayout">
      <router-view />
      <Toasts />
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Toasts from "@/components/Toasts.vue";
import Loader from "@/components/LoaderMain.vue";

export default {
  name: "App",
  components: { Toasts, Loader },
  data() {
    return {};
  },
  created() {
    // this.$store.dispatch("tryAutoLogin");
    window.addEventListener("beforeunload", this.leaving);
    localStorage.setItem("Konfrence_testing", "testing");
    var lan = localStorage.getItem("KonfrenceLnaguage");
    this.$store.dispatch("getLanguages", lan);
  },
  computed: {
    ...mapState(["user"]),
    currentLayout() {
      if (this.user != null) {
        return this.user.roles === 1
          ? "AdminLayout"
          : this.user.roles === 2
          ? "PartnerLayout"
          : "";
      } else {
        return "CustomerLayout";
      }
    },
  },
  methods: {
    leaving() {
      localStorage.removeItem("Konfrence_testing");
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
